function setNav(nav) {

    var current = findCurrentNav(nav);
    setNavHover(current);

    var intval = 0;
    $('.nav-box .navbar-collapse').hover(function () {
        clearInterval(intval)
    }, function () {
        intval = setInterval(function () {
            setNavHover(current);
        }, 500)
    })
    $('.nav-box .nav-item').hover(function () {
        var index = $('.main-nav .nav-item').index(this);
        setNavHover(index);
    }, function () {

    })
}

function setNavHover(index) {
    var items = $('.main-nav .nav-item');
    items.removeClass('active prev-hover')
    items.eq(index).addClass('active');
    $('.main-nav .nav-item.active').prev().addClass('prev-hover');

    $('.nav-box .nav-bg').css('right', (items.length - index - 1) * items.eq(0).outerWidth())
}

function findCurrentNav(nav) {
    var items = $('.main-nav .nav-item');

    for (var i = 0; i < items.length; i++) {
        if (items.eq(i).data('model') === nav) {
            return i;
        }
    }
    var pnav = nav.substr(0, nav.lastIndexOf('-'));
    if (pnav == nav || !pnav) {
        return 0;
    }
    return findCurrentNav(pnav);
}

function editData(id, url, tpl, name) {
    var dlg = new Dialog({
        onshown: function (body) {
            if ($.fn.datetimepicker) {
                body.find('.datepicker').each(function () {
                    $(this).datetimepicker({ format: 'YYYY-MM-DD' })
                })
            }
            if (id > 0) {
                $.ajax({
                    url: url + '?id=' + id,
                    dataType: 'JSON',
                    type: 'GET',
                    success: function (json) {
                        //console.log(json);
                        if (json.code == 1) {
                            bindData(body, json.data.model);
                        }
                    }
                })
            }
        },
        onsure: function (body) {
            var options = {
                url: url,
                type: 'POST',
                dataType: 'JSON',
                success: function (json) {
                    //console.log(json);
                    dialog.alert(json.msg);
                    if (json.code == 1) {
                        location.reload();
                        dlg.close();
                    }
                },
                error: function (err) {
                    dialog.error(err.toString())
                }
            }
            var form = body.find('form')
            if (form.length > 0) {
                if (form.attr('enctype') == 'multipart/form-data') {
                    options.data = new FormData(form[0])
                    options.cache = false;
                    options.processData = false;
                    options.contentType = false;
                } else {
                    options.data = $(form).serialize()
                }
            } else {
                options.data = getData(body)
            }
            $.ajax(options);
            return false;
        }
    }).show(tpl, (id > 0 ? '编辑' : '添加') + name);
}

//绑定数据
function bindData(body, data) {
    for (var i in data) {
        body.find('[name=' + i + ']').val(data[i]);
    }
}

//获取表单数据
function getData(body) {
    var data = {};
    var fields = body.find('[name]');
    for (var i = 0; i < fields.length; i++) {
        data[fields.eq(i).attr('name')] = fields.eq(i).val();
    }
    return data;
}

function uploadFile(url, file, onsuccess, onerror, field, onprogress) {


    if (!FormData) {
        dialog.error('浏览器不支持异步上传');
        return false;
    }

    var options = {
        url: url,
        type: 'POST',
        dataType: 'JSON',
        success: function (json) {

            if (json.code == 1) {
                if (onsuccess) onsuccess(json)
            } else {
                dialog.warning(json.msg);

                if (onerror) onerror(json)
            }
        },
        error: function (xhr) {

            dialog.error('服务器处理错误');
            if (onerror) onerror()
        }
    };

    options.data = new FormData();
    options.data.append(field ? field : 'file_upload', file.files[0]);
    options.cache = false;
    options.processData = false;
    options.contentType = false;
    options.xhr = function () { //用以显示上传进度
        var xhr = $.ajaxSettings.xhr();
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', function (event) {
                var percent = Math.floor(event.loaded / event.total * 100);
                if (onprogress) {
                    onprogress(percent)
                }
            }, false);
        }
        return xhr;
    };

    $.ajax(options);
}

jQuery(function ($) {
    if ($(window).width() >= 991) {
        $('.main-nav>.dropdown').hover(
            function () {
                $(this).find('.dropdown-menu').stop(true, false).slideDown();
            },
            function () {
                $(this).find('.dropdown-menu').stop(true, false).slideUp();
            }
        );
    } else {
        $('.main-nav>.dropdown>.dropdown-toggle').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            var opened = $(this).data('opened');
            var p = $(this).parents('.dropdown');
            if (opened) {
                p.find('.dropdown-menu').stop(true, false).slideUp();
            } else {
                p.siblings().children('.dropdown-menu').stop(true, false).slideUp();
                p.siblings().children('.dropdown-toggle').data('opened', false);
                p.find('.dropdown-menu').stop(true, false).slideDown();
            }
            $(this).data('opened', !opened);
        })
    }

    $('.btn-confirm').click(function (e) {
        e.preventDefault()
        var text = $(this).data('confirm')
        var url = $(this).attr('href')
        dialog.confirm(text, function () {
            location.href = url
        })
    })

    $('form.ajax').each(function (idx, form) {
        form = $(form)
        var verify = form.find('[name=verify]')
        form.on('submit', function (e) {

            var options = {
                url: form.attr('action'),
                type: form.attr('method'),
                dataType: 'JSON',
                success: function (json) {
                    if (json.code == 1) {
                        dialog.alert(json.msg, function () {
                            if (json.url) {
                                location.href = json.url;
                            } else {
                                location.reload();
                            }
                        });
                    } else {
                        if (json.url) {
                            var confirm = '确定'
                            if (json.data && json.data.confirm) {
                                confirm = json.data.confirm
                            }
                            dialog.confirm(
                                { content: json.msg, confirm_text: confirm },
                                function () {
                                    window.open(json.url)
                                }
                            )
                        } else {
                            dialog.warning(json.msg);
                        }
                        if (verify.length > 0) {
                            verify.val('')
                            form.find('.verifybox').trigger('click')
                        }
                    }
                },
                error: function (xhr) {
                    dialog.error('服务器处理错误');
                }
            };
            if (form.attr('enctype') === 'multipart/form-data') {
                if (!FormData) {
                    return true;
                }
                options.data = new FormData(form[0]);
                options.cache = false;
                options.processData = false;
                options.contentType = false;
                options.xhr = function () { //用以显示上传进度
                    var xhr = $.ajaxSettings.xhr();
                    if (xhr.upload) {
                        xhr.upload.addEventListener('progress', function (event) {
                            var percent = Math.floor(event.loaded / event.total * 100);
                            //$(btn).text(origText+'  ('+percent+'%)');
                        }, false);
                    }
                    return xhr;
                };
            } else {
                options.data = form.serialize();
            }
            e.preventDefault();
            $.ajax(options);
        })

    });
});